//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowIcon from '~/assets/svg/ic-arrow-right.svg?inline';
import SwiperMixin from '~/mixins/swiper.mixin.js';

export default {
  components: { ArrowIcon },
  mixins: [SwiperMixin],

  data() {
    return {
      slides: [
        {
          img: require('~/assets/images/index1/studio/studio_1.jpg'),
          tr: 'rt0822.studio.slide1.text',
        },
        {
          img: require('~/assets/images/index1/studio/studio_2.jpg'),
          tr: 'rt0822.studio.slide2.text',
        },
        {
          img: require('~/assets/images/index1/studio/studio_3.jpg'),
          tr: 'rt0822.studio.slide3.text',
        },
        {
          img: require('~/assets/images/index1/studio/studio_4.jpg'),
          tr: 'rt0822.studio.slide4.text',
        },
        {
          img: require('~/assets/images/index1/studio/studio_5.jpg'),
          tr: 'rt0822.studio.slide5.text',
        },
      ],
    };
  },

  mounted() {
    this.initSwiper();
  },
};
