//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    review: {
      type: Object,
    },
  },
  data() {
    return {
      center: false,
    };
  },
  async mounted() {
    this.center = this.$refs.top.clientHeight <= 48;
  },
  methods: {
    format(date, mask) {
      return this.$dayjs(date).format(mask);
    },
    utcOffset(utcString) {
      return this.$dayjs
        .utc(utcString)
        .utcOffset(this.$app.config.current.utcOffset);
    },
  },
};
