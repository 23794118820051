//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViewportMixin from '@/mixins/viewport.mixin';
import TextReview from './components/textReview.vue';
import MoreButton from '../MoreButton';

export default {
  components: {
    TextReview,
    MoreButton,
  },
  mixins: [ViewportMixin],
  props: {
    needMoreButtonInMobileVersion: {
      type: Boolean,
    },
  },
  data() {
    return {
      reviews: [],
      isMobile: false,
      mobileReviews: [],
    };
  },
  async fetch() {
    let responseReviews;
    try {
      responseReviews = await this.$axios({
        method: 'GET',
        url: `${this.$nuxt.$config.CMS_REST_API}/public/v2/testimonials/`,
        params: {
          limit: 10,
          offset: 0,
          showOnMain: true,
        },
      });
    } catch (e) {
      console.error(e);
    }

    if (responseReviews) {
      if (responseReviews.data.error) {
        throw new Error(
          `An error occurred while executing the query (${responseReviews.data.error})`,
        );
      }
      this.reviews = responseReviews.data.data.data;
      this.mobileReviews = this.reviews.slice(0, 3);
    }
  },
  computed: {
    firstRow() {
      return [
        ...this.reviews.slice(7, 8),
        ...this.reviews.slice(8, 9),
        ...this.reviews.slice(3, 4),
      ];
    },
    lastRows() {
      return [
        ...this.reviews.slice(6, 7),
        ...this.reviews.slice(0, 1),
        ...this.reviews.slice(5, 6),
        ...this.reviews.slice(4, 5),
      ];
    },
  },
  methods: {
    format(date, mask) {
      return this.$dayjs(date).format(mask);
    },
    utcOffset(utcString) {
      return this.$dayjs
        .utc(utcString)
        .utcOffset(this.$app.config.current.utcOffset);
    },
    showMore() {
      this.mobileReviews = this.reviews;
      this.$refs?.mobileReviews?.classList.add('review-text__body--expanded');
    },
  },
};
