/** Составить список спикеров, перебрав топики ивента
 *  У ивента есть 2 вида
 *  В одном - заполнен массив топиков, в котором есть спикеры
 *  В другом - спикеры в отдельном объекте
 */
export default function getSpeakersArray(event) {
  if (!event) return [];
  const speakers = [];

  let array;
  if (typeof event.topics?.[0] === 'number') {
    array = event.speakers;
  } else {
    array = event.topics;
  }

  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (!item.speakerName) continue;

    const speaker = {
      name: item.speakerName,
      text: item.speakerText,
      subtitle: item.speakerSubtitle,
      image: item.images?.speakerImage?.url || item.speakerImage?.url,

      // Иногда нужно, чтобы определить, к какому топику относится спикер
      topic_num: item.num,
    };

    speakers.push(speaker);
  }

  return speakers;
}
