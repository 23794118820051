//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    img: {
      type: Object,
      default: () => ({
        src: '',
        srcset: '',
        placeholder: '',
      }),
    },
  },
  data() {
    const placeholder = this.img.placeholder;

    return {
      styles: `background-image: url('${placeholder}')`,
    };
  },
  methods: {
    onLoad() {
      this.styles = '';
    },
  },
};
