import { render, staticRenderFns } from "./popular.vue?vue&type=template&id=210c5b0c&scoped=true&"
import script from "./popular.vue?vue&type=script&lang=js&"
export * from "./popular.vue?vue&type=script&lang=js&"
import style0 from "./popular.vue?vue&type=style&index=0&id=210c5b0c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210c5b0c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImg: require('/app/landing_generator/components/lazy-img.vue').default,EBtn: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/btn/index.vue').default})
