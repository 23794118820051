//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import generateResizerLink from '@/utils/generateResizerLink';

// const exampleConfig = [
//   {
//     mobile: false,
//     ext: 'webp',
//     sizes: ['156x120 1x', '312x240 2x'],
//   },
//   {
//     mobile: false,
//     ext: 'jpeg',
//     sizes: ['156x120 1x', '312x240 2x'],
//   },
//   {
//     mobile: true,
//     ext: 'webp',
//     sizes: ['156x120 320w', '312x240 700w'],
//   },
//   {
//     media: '(max-width: 400px)',
//     ext: 'jpeg',
//     sizes: ['156x120 1x', '312x240 2x'],
//   },
// ]

export default {
  name: 'BaseImage',
  inheritAttrs: false,
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    config: {
      type: Array,
      default: null,
    },
  },
  computed: {
    srcsetJpeg() {
      if (this.config?.length > 0) {
        return generateResizerLink({
          link: this.imgSrc,
          ext: 'jpeg',
          size: this.minSize(),
        });
      }

      return this.imgSrc;
    },
  },
  methods: {
    minSize() {
      let minW = 10000;
      let minH;
      // search min size image
      this.config.forEach((cfg) =>
        cfg.sizes.forEach((e) => {
          const [size] = e.split(/\s+/);
          if (!size) {
            return;
          }
          const [w, h] = size.split('x');
          if (Number(w) <= minW) {
            minW = Number(w);
            minH = Number(h);
          }
        }),
      );

      return minW === 10000 ? '' : `${minW}x${minH}`;
    },
    srcsetCalc(config) {
      const { ext, sizes } = config;
      let result = '';
      if (sizes.length > 0) {
        const [size, ...srcsetWidth] = sizes[0].split(/\s+/);

        result =
          generateResizerLink({ link: this.imgSrc, ext, size }) +
          ' ' +
          (srcsetWidth.join(' ') || '1x');
      }
      if (sizes.length > 1) {
        const [size, ...srcsetWidth] = sizes[1].split(/\s+/);
        result +=
          ', ' +
          generateResizerLink({ link: this.imgSrc, ext, size }) +
          ' ' +
          (srcsetWidth.join(' ') || '2x');
      }

      return result;
    },
    /** Коллбек срабатывает, когда изображение загрузилось */
    onLoad() {
      this.$emit('load');
    },
  },
};
