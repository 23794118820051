import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

const SwiperScreenEdgeArrowsOffset = 60.0;

Swiper.use([Navigation]);
export default {
  props: {
    spaceBetween: {
      type: Number,
      default: 0,
    },
    slidesPerColumnFill: {
      type: String,
    },
    slidesPerColumn: {
      type: Number,
    },
  },
  data() {
    return {
      swiper: null,
      threshold: 0,
      slidesPerView: 'auto',
      noOffsetAfter: false,
    };
  },

  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },

  computed: {
    /** Контролы могут быть вынесены в дочерний компонент
     *  Тогда обращаемся к ним через дочерний реф
     */
    controlNext() {
      return this.$refs.arrowRight || this.$refs.controls?.$refs.arrowRight;
    },
    controlPrev() {
      return this.$refs.arrowLeft || this.$refs.controls?.$refs.arrowLeft;
    },
  },

  methods: {
    initSwiper() {
      this.debug({ name: 'initSwiper' });
      if (this.$refs.swiper) {
        this.swiper = new Swiper(this.$refs.swiper, {
          direction: 'horizontal',
          slidesPerView: this.slidesPerView,
          navigation: {
            nextEl: this.controlNext,
            prevEl: this.controlPrev,
          },
          autoHeight: this.autoHeight || false,
          spaceBetween: this.spaceBetween,
          slidesOffsetAfter: this.getAfterOffset(),
          threshold: this.threshold,
          slidesPerColumnFill: this.slidesPerColumnFill,
          slidesPerColumn: this.slidesPerColumn,
        });

        this.debug({ name: 'initSwiper' }, 'swiper = %O', this.swiper);
      }
    },
    onWindowResize() {
      this.debug({ name: 'onWindowResize' });
      if (this.swiper !== null) {
        this.swiper.params.slidesOffsetAfter = this.getAfterOffset();
        this.swiper.updateSize();
      }
    },
    getAfterOffset() {
      if (this.noOffsetAfter) return 0;
      if (this.$refs.swiper) {
        let offset = (window.innerWidth - this.$refs.swiper.clientWidth) / 2;
        return offset > SwiperScreenEdgeArrowsOffset
          ? -(offset - SwiperScreenEdgeArrowsOffset)
          : 0;
      }
    },
  },
};
