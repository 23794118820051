/** Получить дату в формате "12 de março de 2024" */
export function getDate(vm, textUtcDate) {
  return vm.$dayjs
    .utc(textUtcDate)
    .utcOffset(vm.$app.config.current.utcOffset)
    .locale(vm.$i18n.locale)
    .format('DD [de] MMMM [de] YYYY');
}

// Получить время в формате "19:00"
export function getTime(vm, textUtcDate) {
  return vm.$dayjs
    .utc(textUtcDate)
    .utcOffset(vm.$app.config.current.utcOffset)
    .locale(vm.$i18n.locale)
    .format('HH:mm');
}
