//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  computed: {
    paragraphs() {
      const paragraphs = this.content.split('\n\n');
      this.debug({ name: 'comp paragraphs' }, '%O', paragraphs);
      return paragraphs;
    },
  },
};
