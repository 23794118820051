import { render, staticRenderFns } from "./webinars.vue?vue&type=template&id=d981f240&scoped=true&"
import script from "./webinars.vue?vue&type=script&lang=js&"
export * from "./webinars.vue?vue&type=script&lang=js&"
import style0 from "./webinars.vue?vue&type=style&index=0&id=d981f240&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d981f240",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EBtn: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/btn/index.vue').default})
