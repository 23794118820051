export default function imageS3Resize({ ext = null, link = '', size = '' }) {
  let params = null;
  const [width, height] = size.split('x');

  if (width || height) {
    params = `${width || 'AUTO'}x${height || 'AUTO'}`;
  }

  const url = link?.match(
    // eslint-disable-next-line no-useless-escape
    /^((\w+):)?(\/\/((\w+)?(:(\w+))?@)?([^\/\?:]+)(:(\d+))?)?(\/?([^\/\?#][^\?#]*)?)?(\?([^#]+))?(#(\w*))?/,
  );
  if (!url) return link;

  const parsePath = url[11]?.match(
    /(?:(?<directory>[\w\d\-/]+)\/)*(?<filename>[\d\w]+)\.(?<ext>[\d\w]+)$/,
  );
  if (!parsePath) return link;

  const filename = params
    ? `/${parsePath?.groups?.filename}/${params}`
    : `/${parsePath?.groups?.filename}/original`;

  const path = `${url[1]}${url[3]}${parsePath?.groups?.directory}${filename}${
    ext ? '.' + ext : '.' + parsePath?.groups?.ext
  }`;

  return path;
}
