//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getSpeakersArray from '@/utils/event/getSpeakersArray';
import BaseImage from '@/components/common/BaseImage.vue';

export default {
  components: { BaseImage },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    light: {
      type: Boolean,
      default: false,
    },
    // По-умолчанию отрисовывается максимум 5 спикеров
    // Чтобы не ломать вёрстку
    limit: {
      type: Number,
      default: 5,
    },
    imageConfig: {
      type: Array,
      default: () => [
        { ext: 'webp', sizes: ['100x100 1x', '200x200 2x'] },
        { ext: 'png', sizes: ['100x100 1x', '200x200 2x'] },
      ],
    },
  },
  computed: {
    list() {
      const speakers = getSpeakersArray(this.event);
      if (!speakers?.length) return [];

      return speakers.slice(0, this.limit);
    },
    multiple() {
      return this.list?.length > 1;
    },
  },
};
