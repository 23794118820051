export const images = {
  // Детальная страница вебинара
  detailed: {
    background: {
      large: [
        require('~/assets/images/events/detailed/background/large/1.jpg'),
        require('~/assets/images/events/detailed/background/large/2.jpg'),
        require('~/assets/images/events/detailed/background/large/3.jpg'),
        require('~/assets/images/events/detailed/background/large/4.jpg'),
        require('~/assets/images/events/detailed/background/large/5.jpg'),
        require('~/assets/images/events/detailed/background/large/6.jpg'),
        require('~/assets/images/events/detailed/background/large/7.jpg'),
        require('~/assets/images/events/detailed/background/large/8.jpg'),
        require('~/assets/images/events/detailed/background/large/9.jpg'),
        require('~/assets/images/events/detailed/background/large/10.jpg'),
        require('~/assets/images/events/detailed/background/large/11.jpg'),
        require('~/assets/images/events/detailed/background/large/12.jpg'),
      ],
      large2x: [
        require('~/assets/images/events/detailed/background/large/1@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/2@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/3@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/4@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/5@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/6@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/7@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/8@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/9@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/10@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/11@2x.jpg'),
        require('~/assets/images/events/detailed/background/large/12@2x.jpg'),
      ],

      medium: [
        require('~/assets/images/events/detailed/background/medium/1.jpg'),
        require('~/assets/images/events/detailed/background/medium/2.jpg'),
        require('~/assets/images/events/detailed/background/medium/3.jpg'),
        require('~/assets/images/events/detailed/background/medium/4.jpg'),
        require('~/assets/images/events/detailed/background/medium/5.jpg'),
        require('~/assets/images/events/detailed/background/medium/6.jpg'),
        require('~/assets/images/events/detailed/background/medium/7.jpg'),
        require('~/assets/images/events/detailed/background/medium/8.jpg'),
        require('~/assets/images/events/detailed/background/medium/9.jpg'),
        require('~/assets/images/events/detailed/background/medium/10.jpg'),
        require('~/assets/images/events/detailed/background/medium/11.jpg'),
        require('~/assets/images/events/detailed/background/medium/12.jpg'),
      ],
      medium2x: [
        require('~/assets/images/events/detailed/background/medium/1@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/2@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/3@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/4@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/5@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/6@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/7@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/8@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/9@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/10@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/11@2x.jpg'),
        require('~/assets/images/events/detailed/background/medium/12@2x.jpg'),
      ],

      small: [
        require('~/assets/images/events/detailed/background/small/1.jpg'),
        require('~/assets/images/events/detailed/background/small/2.jpg'),
        require('~/assets/images/events/detailed/background/small/3.jpg'),
        require('~/assets/images/events/detailed/background/small/4.jpg'),
        require('~/assets/images/events/detailed/background/small/5.jpg'),
        require('~/assets/images/events/detailed/background/small/6.jpg'),
        require('~/assets/images/events/detailed/background/small/7.jpg'),
        require('~/assets/images/events/detailed/background/small/8.jpg'),
        require('~/assets/images/events/detailed/background/small/9.jpg'),
        require('~/assets/images/events/detailed/background/small/10.jpg'),
        require('~/assets/images/events/detailed/background/small/11.jpg'),
        require('~/assets/images/events/detailed/background/small/12.jpg'),
      ],
      small2x: [
        require('~/assets/images/events/detailed/background/small/1@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/2@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/3@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/4@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/5@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/6@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/7@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/8@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/9@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/10@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/11@2x.jpg'),
        require('~/assets/images/events/detailed/background/small/12@2x.jpg'),
      ],
    },

    figure: {
      large: [
        require('~/assets/images/events/detailed/figure/large/1.png'),
        require('~/assets/images/events/detailed/figure/large/2.png'),
        require('~/assets/images/events/detailed/figure/large/3.png'),
        require('~/assets/images/events/detailed/figure/large/4.png'),
        require('~/assets/images/events/detailed/figure/large/5.png'),
        require('~/assets/images/events/detailed/figure/large/6.png'),
        require('~/assets/images/events/detailed/figure/large/7.png'),
        require('~/assets/images/events/detailed/figure/large/8.png'),
        require('~/assets/images/events/detailed/figure/large/9.png'),
        require('~/assets/images/events/detailed/figure/large/10.png'),
        require('~/assets/images/events/detailed/figure/large/11.png'),
        require('~/assets/images/events/detailed/figure/large/12.png'),
      ],
      large2x: [
        require('~/assets/images/events/detailed/figure/large/1@2x.png'),
        require('~/assets/images/events/detailed/figure/large/2@2x.png'),
        require('~/assets/images/events/detailed/figure/large/3@2x.png'),
        require('~/assets/images/events/detailed/figure/large/4@2x.png'),
        require('~/assets/images/events/detailed/figure/large/5@2x.png'),
        require('~/assets/images/events/detailed/figure/large/6@2x.png'),
        require('~/assets/images/events/detailed/figure/large/7@2x.png'),
        require('~/assets/images/events/detailed/figure/large/8@2x.png'),
        require('~/assets/images/events/detailed/figure/large/9@2x.png'),
        require('~/assets/images/events/detailed/figure/large/10@2x.png'),
        require('~/assets/images/events/detailed/figure/large/11@2x.png'),
        require('~/assets/images/events/detailed/figure/large/12@2x.png'),
      ],

      medium: [
        require('~/assets/images/events/detailed/figure/medium/1.png'),
        require('~/assets/images/events/detailed/figure/medium/2.png'),
        require('~/assets/images/events/detailed/figure/medium/3.png'),
        require('~/assets/images/events/detailed/figure/medium/4.png'),
        require('~/assets/images/events/detailed/figure/medium/5.png'),
        require('~/assets/images/events/detailed/figure/medium/6.png'),
        require('~/assets/images/events/detailed/figure/medium/7.png'),
        require('~/assets/images/events/detailed/figure/medium/8.png'),
        require('~/assets/images/events/detailed/figure/medium/9.png'),
        require('~/assets/images/events/detailed/figure/medium/10.png'),
        require('~/assets/images/events/detailed/figure/medium/11.png'),
        require('~/assets/images/events/detailed/figure/medium/12.png'),
      ],
      medium2x: [
        require('~/assets/images/events/detailed/figure/medium/1@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/2@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/3@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/4@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/5@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/6@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/7@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/8@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/9@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/10@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/11@2x.png'),
        require('~/assets/images/events/detailed/figure/medium/12@2x.png'),
      ],
    },
  },

  // Карточки вебинаров (детальная страница и рекомендации)
  card: {
    background: {
      large: [
        require('~/assets/images/events/card/background/large/1.jpg'),
        require('~/assets/images/events/card/background/large/2.jpg'),
        require('~/assets/images/events/card/background/large/3.jpg'),
        require('~/assets/images/events/card/background/large/4.jpg'),
        require('~/assets/images/events/card/background/large/5.jpg'),
        require('~/assets/images/events/card/background/large/6.jpg'),
        require('~/assets/images/events/card/background/large/7.jpg'),
        require('~/assets/images/events/card/background/large/8.jpg'),
        require('~/assets/images/events/card/background/large/9.jpg'),
        require('~/assets/images/events/card/background/large/10.jpg'),
        require('~/assets/images/events/card/background/large/11.jpg'),
        require('~/assets/images/events/card/background/large/12.jpg'),
      ],
      large2x: [
        require('~/assets/images/events/card/background/large/1@2x.jpg'),
        require('~/assets/images/events/card/background/large/2@2x.jpg'),
        require('~/assets/images/events/card/background/large/3@2x.jpg'),
        require('~/assets/images/events/card/background/large/4@2x.jpg'),
        require('~/assets/images/events/card/background/large/5@2x.jpg'),
        require('~/assets/images/events/card/background/large/6@2x.jpg'),
        require('~/assets/images/events/card/background/large/7@2x.jpg'),
        require('~/assets/images/events/card/background/large/8@2x.jpg'),
        require('~/assets/images/events/card/background/large/9@2x.jpg'),
        require('~/assets/images/events/card/background/large/10@2x.jpg'),
        require('~/assets/images/events/card/background/large/11@2x.jpg'),
        require('~/assets/images/events/card/background/large/12@2x.jpg'),
      ],

      medium: [
        require('~/assets/images/events/card/background/medium/1.jpg'),
        require('~/assets/images/events/card/background/medium/2.jpg'),
        require('~/assets/images/events/card/background/medium/3.jpg'),
        require('~/assets/images/events/card/background/medium/4.jpg'),
        require('~/assets/images/events/card/background/medium/5.jpg'),
        require('~/assets/images/events/card/background/medium/6.jpg'),
        require('~/assets/images/events/card/background/medium/7.jpg'),
        require('~/assets/images/events/card/background/medium/8.jpg'),
        require('~/assets/images/events/card/background/medium/9.jpg'),
        require('~/assets/images/events/card/background/medium/10.jpg'),
        require('~/assets/images/events/card/background/medium/11.jpg'),
        require('~/assets/images/events/card/background/medium/12.jpg'),
      ],
      medium2x: [
        require('~/assets/images/events/card/background/medium/1@2x.jpg'),
        require('~/assets/images/events/card/background/medium/2@2x.jpg'),
        require('~/assets/images/events/card/background/medium/3@2x.jpg'),
        require('~/assets/images/events/card/background/medium/4@2x.jpg'),
        require('~/assets/images/events/card/background/medium/5@2x.jpg'),
        require('~/assets/images/events/card/background/medium/6@2x.jpg'),
        require('~/assets/images/events/card/background/medium/7@2x.jpg'),
        require('~/assets/images/events/card/background/medium/8@2x.jpg'),
        require('~/assets/images/events/card/background/medium/9@2x.jpg'),
        require('~/assets/images/events/card/background/medium/10@2x.jpg'),
        require('~/assets/images/events/card/background/medium/11@2x.jpg'),
        require('~/assets/images/events/card/background/medium/12@2x.jpg'),
      ],

      small: [
        require('~/assets/images/events/card/background/small/1.jpg'),
        require('~/assets/images/events/card/background/small/2.jpg'),
        require('~/assets/images/events/card/background/small/3.jpg'),
        require('~/assets/images/events/card/background/small/4.jpg'),
        require('~/assets/images/events/card/background/small/5.jpg'),
        require('~/assets/images/events/card/background/small/6.jpg'),
        require('~/assets/images/events/card/background/small/7.jpg'),
        require('~/assets/images/events/card/background/small/8.jpg'),
        require('~/assets/images/events/card/background/small/9.jpg'),
        require('~/assets/images/events/card/background/small/10.jpg'),
        require('~/assets/images/events/card/background/small/11.jpg'),
        require('~/assets/images/events/card/background/small/12.jpg'),
      ],
      small2x: [
        require('~/assets/images/events/card/background/small/1@2x.jpg'),
        require('~/assets/images/events/card/background/small/2@2x.jpg'),
        require('~/assets/images/events/card/background/small/3@2x.jpg'),
        require('~/assets/images/events/card/background/small/4@2x.jpg'),
        require('~/assets/images/events/card/background/small/5@2x.jpg'),
        require('~/assets/images/events/card/background/small/6@2x.jpg'),
        require('~/assets/images/events/card/background/small/7@2x.jpg'),
        require('~/assets/images/events/card/background/small/8@2x.jpg'),
        require('~/assets/images/events/card/background/small/9@2x.jpg'),
        require('~/assets/images/events/card/background/small/10@2x.jpg'),
        require('~/assets/images/events/card/background/small/11@2x.jpg'),
        require('~/assets/images/events/card/background/small/12@2x.jpg'),
      ],

      micro: [
        require('~/assets/images/events/card/background/micro/1.jpg'),
        require('~/assets/images/events/card/background/micro/2.jpg'),
        require('~/assets/images/events/card/background/micro/3.jpg'),
        require('~/assets/images/events/card/background/micro/4.jpg'),
        require('~/assets/images/events/card/background/micro/5.jpg'),
        require('~/assets/images/events/card/background/micro/6.jpg'),
        require('~/assets/images/events/card/background/micro/7.jpg'),
        require('~/assets/images/events/card/background/micro/8.jpg'),
        require('~/assets/images/events/card/background/micro/9.jpg'),
        require('~/assets/images/events/card/background/micro/10.jpg'),
        require('~/assets/images/events/card/background/micro/11.jpg'),
        require('~/assets/images/events/card/background/micro/12.jpg'),
      ],
      micro2x: [
        require('~/assets/images/events/card/background/micro/1@2x.jpg'),
        require('~/assets/images/events/card/background/micro/2@2x.jpg'),
        require('~/assets/images/events/card/background/micro/3@2x.jpg'),
        require('~/assets/images/events/card/background/micro/4@2x.jpg'),
        require('~/assets/images/events/card/background/micro/5@2x.jpg'),
        require('~/assets/images/events/card/background/micro/6@2x.jpg'),
        require('~/assets/images/events/card/background/micro/7@2x.jpg'),
        require('~/assets/images/events/card/background/micro/8@2x.jpg'),
        require('~/assets/images/events/card/background/micro/9@2x.jpg'),
        require('~/assets/images/events/card/background/micro/10@2x.jpg'),
        require('~/assets/images/events/card/background/micro/11@2x.jpg'),
        require('~/assets/images/events/card/background/micro/12@2x.jpg'),
      ],
    },

    figure: {
      large: [
        require('~/assets/images/events/card/figure/large/1.png'),
        require('~/assets/images/events/card/figure/large/2.png'),
        require('~/assets/images/events/card/figure/large/3.png'),
        require('~/assets/images/events/card/figure/large/4.png'),
        require('~/assets/images/events/card/figure/large/5.png'),
        require('~/assets/images/events/card/figure/large/6.png'),
        require('~/assets/images/events/card/figure/large/7.png'),
        require('~/assets/images/events/card/figure/large/8.png'),
        require('~/assets/images/events/card/figure/large/9.png'),
        require('~/assets/images/events/card/figure/large/10.png'),
        require('~/assets/images/events/card/figure/large/11.png'),
        require('~/assets/images/events/card/figure/large/12.png'),
      ],
      large2x: [
        require('~/assets/images/events/card/figure/large/1@2x.png'),
        require('~/assets/images/events/card/figure/large/2@2x.png'),
        require('~/assets/images/events/card/figure/large/3@2x.png'),
        require('~/assets/images/events/card/figure/large/4@2x.png'),
        require('~/assets/images/events/card/figure/large/5@2x.png'),
        require('~/assets/images/events/card/figure/large/6@2x.png'),
        require('~/assets/images/events/card/figure/large/7@2x.png'),
        require('~/assets/images/events/card/figure/large/8@2x.png'),
        require('~/assets/images/events/card/figure/large/9@2x.png'),
        require('~/assets/images/events/card/figure/large/10@2x.png'),
        require('~/assets/images/events/card/figure/large/11@2x.png'),
        require('~/assets/images/events/card/figure/large/12@2x.png'),
      ],

      medium: [
        require('~/assets/images/events/card/figure/medium/1.png'),
        require('~/assets/images/events/card/figure/medium/2.png'),
        require('~/assets/images/events/card/figure/medium/3.png'),
        require('~/assets/images/events/card/figure/medium/4.png'),
        require('~/assets/images/events/card/figure/medium/5.png'),
        require('~/assets/images/events/card/figure/medium/6.png'),
        require('~/assets/images/events/card/figure/medium/7.png'),
        require('~/assets/images/events/card/figure/medium/8.png'),
        require('~/assets/images/events/card/figure/medium/9.png'),
        require('~/assets/images/events/card/figure/medium/10.png'),
        require('~/assets/images/events/card/figure/medium/11.png'),
        require('~/assets/images/events/card/figure/medium/12.png'),
      ],
      medium2x: [
        require('~/assets/images/events/card/figure/medium/1@2x.png'),
        require('~/assets/images/events/card/figure/medium/2@2x.png'),
        require('~/assets/images/events/card/figure/medium/3@2x.png'),
        require('~/assets/images/events/card/figure/medium/4@2x.png'),
        require('~/assets/images/events/card/figure/medium/5@2x.png'),
        require('~/assets/images/events/card/figure/medium/6@2x.png'),
        require('~/assets/images/events/card/figure/medium/7@2x.png'),
        require('~/assets/images/events/card/figure/medium/8@2x.png'),
        require('~/assets/images/events/card/figure/medium/9@2x.png'),
        require('~/assets/images/events/card/figure/medium/10@2x.png'),
        require('~/assets/images/events/card/figure/medium/11@2x.png'),
        require('~/assets/images/events/card/figure/medium/12@2x.png'),
      ],

      small: [
        require('~/assets/images/events/card/figure/small/1.png'),
        require('~/assets/images/events/card/figure/small/2.png'),
        require('~/assets/images/events/card/figure/small/3.png'),
        require('~/assets/images/events/card/figure/small/4.png'),
        require('~/assets/images/events/card/figure/small/5.png'),
        require('~/assets/images/events/card/figure/small/6.png'),
        require('~/assets/images/events/card/figure/small/7.png'),
        require('~/assets/images/events/card/figure/small/8.png'),
        require('~/assets/images/events/card/figure/small/9.png'),
        require('~/assets/images/events/card/figure/small/10.png'),
        require('~/assets/images/events/card/figure/small/11.png'),
        require('~/assets/images/events/card/figure/small/12.png'),
      ],
      small2x: [
        require('~/assets/images/events/card/figure/small/1@2x.png'),
        require('~/assets/images/events/card/figure/small/2@2x.png'),
        require('~/assets/images/events/card/figure/small/3@2x.png'),
        require('~/assets/images/events/card/figure/small/4@2x.png'),
        require('~/assets/images/events/card/figure/small/5@2x.png'),
        require('~/assets/images/events/card/figure/small/6@2x.png'),
        require('~/assets/images/events/card/figure/small/7@2x.png'),
        require('~/assets/images/events/card/figure/small/8@2x.png'),
        require('~/assets/images/events/card/figure/small/9@2x.png'),
        require('~/assets/images/events/card/figure/small/10@2x.png'),
        require('~/assets/images/events/card/figure/small/11@2x.png'),
        require('~/assets/images/events/card/figure/small/12@2x.png'),
      ],

      micro: [
        require('~/assets/images/events/card/figure/micro/1.png'),
        require('~/assets/images/events/card/figure/micro/2.png'),
        require('~/assets/images/events/card/figure/micro/3.png'),
        require('~/assets/images/events/card/figure/micro/4.png'),
        require('~/assets/images/events/card/figure/micro/5.png'),
        require('~/assets/images/events/card/figure/micro/6.png'),
        require('~/assets/images/events/card/figure/micro/7.png'),
        require('~/assets/images/events/card/figure/micro/8.png'),
        require('~/assets/images/events/card/figure/micro/9.png'),
        require('~/assets/images/events/card/figure/micro/10.png'),
        require('~/assets/images/events/card/figure/micro/11.png'),
        require('~/assets/images/events/card/figure/micro/12.png'),
      ],
      micro2x: [
        require('~/assets/images/events/card/figure/micro/1@2x.png'),
        require('~/assets/images/events/card/figure/micro/2@2x.png'),
        require('~/assets/images/events/card/figure/micro/3@2x.png'),
        require('~/assets/images/events/card/figure/micro/4@2x.png'),
        require('~/assets/images/events/card/figure/micro/5@2x.png'),
        require('~/assets/images/events/card/figure/micro/6@2x.png'),
        require('~/assets/images/events/card/figure/micro/7@2x.png'),
        require('~/assets/images/events/card/figure/micro/8@2x.png'),
        require('~/assets/images/events/card/figure/micro/9@2x.png'),
        require('~/assets/images/events/card/figure/micro/10@2x.png'),
        require('~/assets/images/events/card/figure/micro/11@2x.png'),
        require('~/assets/images/events/card/figure/micro/12@2x.png'),
      ],
    },
  },
};
