//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    avatar: {
      type: [Object, String],
      reqiured: true,
    },
    name: {
      type: String,
      reqiured: true,
    },
    text: {
      type: String,
      reqiured: true,
    },
    position: {
      type: String,
      reqiured: true,
      validator: (value) => {
        return ['left', 'right'].includes(value);
      },
    },
  },
};
