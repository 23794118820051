export default {
  computed: {
    isCustomerIoLoaded() {
      return this.$store.state.customerIo.data !== null;
    },
    isCustomerIoLoading() {
      return this.$store.state.customerIo.buzy;
    },
    customerIoData() {
      return this.$store.state.customerIo.data;
    },
    computedUserPhoneFromLMS() {
      return this.$app?.user?.profile?.phone;
    },
    computedUserEmailFromLMS() {
      return this.$app?.user?.email;
    },
    computedUserNameFromLMS() {
      return this.$app?.user?.name;
    },
    computedUserDataFromCio() {
      return this.$store.getters['customerIo/userDataFromCio'];
    },
  },
  async created() {
    if (this.$store.state.customerIo.url === null) {
      this.setCustomerIoUrl();
    }
    this.getCustomerIoData();
  },
  methods: {
    setCustomerIoUrl() {
      this.$store.commit('customerIo/setUrl', this.$config.CUSTOMER_IO_URL);
    },
    getCustomerIoData() {
      if (process.server) return;
      if (this.$app?.user?.id) {
        if (
          this.computedUserPhoneFromLMS &&
          this.computedUserEmailFromLMS &&
          this.computedUserNameFromLMS
        ) {
          return;
        }
      }

      const cio_client_id = this.$route?.query?.cio_client_id;
      const _cio_id = this.$route?.query?._cio_id;
      const cioIdFromCookie =
        this.$app.getCookie('_cioid')?.replace(/cio_/g, '') || null;
      const country = this.$config.COUNTRY;

      if (
        !this.isCustomerIoLoaded &&
        !this.isCustomerIoLoading &&
        !this.computedUserDataFromCio
      ) {
        if (_cio_id) {
          this.$store.dispatch('customerIo/query', {
            cio_client_id: _cio_id,
            country,
          });
          return;
        }
        if (cio_client_id) {
          this.$store.dispatch('customerIo/query', {
            cio_client_id: cio_client_id,
            country,
          });
          return;
        }
        if (cioIdFromCookie) {
          if (cioIdFromCookie.includes('@')) {
            return;
          }
          this.$store.dispatch('customerIo/query', {
            cio_client_id: cioIdFromCookie,
            country,
          });
          return;
        }
      }
    },
    setAttributes() {
      const attributes = this.customerIoData?.customer?.attributes;
      if (!attributes) return;

      const userData = {};

      userData.phone = this.computedUserPhoneFromLMS ? null : attributes.phone;
      userData.email = this.computedUserEmailFromLMS ? null : attributes.email;
      userData.name = this.computedUserNameFromLMS
        ? null
        : attributes.full_name;

      this.$store.commit('customerIo/setUserDataFromCio', userData);
    },
  },
  watch: {
    isCustomerIoLoaded: {
      handler(value) {
        if (value) {
          this.setAttributes();
        }
      },
      immediate: true,
    },
  },
};
