import hash from '../hash';
import { images } from './images';
const length = 11;

export function getEventHash({ name, type }) {
  const str = name + type;
  return hash(str, length);
}

/**
 *
 * @param {*} event Ивент. Нужны поля name и type
 */
export function getEventImages(event) {
  try {
    const types = ['detailed', 'card'];
    const sizes = [
      'large',
      'large2x',
      'medium',
      'medium2x',
      'small',
      'small2x',
      'micro',
      'micro2x',
    ];
    const entities = ['background', 'figure'];

    const index = getEventHash(event);
    const eventImages = {};
    types.forEach((type) => {
      sizes.forEach((size) => {
        entities.forEach((entity) => {
          if (!eventImages[type]) {
            eventImages[type] = {};
          }

          if (!eventImages[type][entity]) {
            eventImages[type][entity] = {};
          }

          eventImages[type][entity][size] =
            images[type]?.[entity]?.[size]?.[index];
        });
      });
    });

    return eventImages;
  } catch (e) {
    console.error(e);
  }
}

/**
 *
 * @param {*} images Объект с картинками из функции getEventImages
 * @param {*} type card или detailed
 * @param {*} size large, medium, small или micro
 * @returns
 */
export function getImagesForOneSize(images, type, size) {
  try {
    const sets = {};

    sets.background = createSrcSet(images, type, size, 'background');
    sets.figure = createSrcSet(images, type, size, 'figure');

    return sets;
  } catch (e) {
    console.error(e);
  }
}

/**
 *
 * @param {*} images Объект с картинками из функции getEventImages
 * @param {*} type card или detailed
 * @param {*} size large, medium, small или micro
 * @param {*} entity background или figure
 * @returns
 */
export function createSrcSet(images, type, size, entity) {
  const image = images?.[type]?.[entity]?.[size];
  const imageSrc = image?.src;
  const image2xSrc = images?.[type]?.[entity]?.[`${size}2x`]?.src;
  const placeholder = image?.placeholder;

  const height = image?.height || 0;
  const width = image?.width || 0;
  const styles = `height: ${height}px; width: ${width}px`;

  return {
    srcset: `${imageSrc}, ${image2xSrc} 2x`,
    styles,
    placeholder,
  };
}
