//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RatingIcon from '~/assets/images/index1/best-course/rating.svg?inline';
export default {
  components: { RatingIcon },
};
