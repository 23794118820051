import { render, staticRenderFns } from "./studio.vue?vue&type=template&id=22d8f622&scoped=true&"
import script from "./studio.vue?vue&type=script&lang=js&"
export * from "./studio.vue?vue&type=script&lang=js&"
import style0 from "./studio.vue?vue&type=style&index=0&id=22d8f622&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d8f622",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImg: require('/app/landing_generator/components/lazy-img.vue').default})
