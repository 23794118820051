//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MoreButton from '../MoreButton';
export default {
  components: {
    MoreButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    needMoreButtonInMobileVersion: {
      type: Boolean,
    },
  },
  computed: {
    professors() {
      let country = this.$nuxt.$config.COUNTRY || 'br';
      if (!['mx', 'br'].includes(country)) country = 'br';

      const professors = [
        require(`~/assets/images/index1/professors/${country}/teacher_1.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_2.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_3.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_4.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_5.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_6.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_7.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_8.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_9.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_10.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_11.jpg`),
        require(`~/assets/images/index1/professors/${country}/teacher_12.jpg`),
      ];

      this.debug({ name: 'comp professors' }, '%O', professors);
      return professors;
    },
  },

  methods: {
    getColumn(columnIdx) {
      let column = [];
      for (
        let professorIdx = columnIdx;
        professorIdx < this.professors.length;
        professorIdx += 4
      ) {
        column.push(this.professors[professorIdx]);
      }

      this.debug(
        { name: 'getColumn' },
        'columnIdx = %d, return = %O',
        columnIdx,
        column,
      );
      return column;
    },

    showMore() {
      this.$refs?.professors?.classList.add('professors--expanded');
    },
  },
};
