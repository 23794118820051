import { render, staticRenderFns } from "./bestCourse.vue?vue&type=template&id=3e38c0e0&scoped=true&"
import script from "./bestCourse.vue?vue&type=script&lang=js&"
export * from "./bestCourse.vue?vue&type=script&lang=js&"
import style0 from "./bestCourse.vue?vue&type=style&index=0&id=3e38c0e0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e38c0e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EBtn: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/btn/index.vue').default,LazyImg: require('/app/landing_generator/components/lazy-img.vue').default})
