//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonIcon from '~/assets/svg/ic-plus-gradient.svg?inline';
import PaddedText from './../components/paddedText.vue';
import ViewportMixin from '@/mixins/viewport.mixin';

export default {
  components: { ButtonIcon, PaddedText },
  mixins: [ViewportMixin],
  props: {
    firstLine: {
      type: String,
      required: true,
    },
    secondLine: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    image: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    uid() {
      return (this.firstLine + this.secondLine).toLowerCase();
    },
  },
  mounted() {
    const svg = this.$refs.button.children[0];
    const linearGradients = svg.getElementsByTagName('linearGradient');

    const makeIdUnique = (svg, linGrad, num, attr) => {
      const fill = svg.children[num];
      const id = fill.getAttribute(attr);
      const idNew = id.slice(0, id.length - 1) + this.uid + ')';
      fill.setAttribute(attr, idNew);
      linGrad[num].id = idNew.slice(5, idNew.length - 1);
    };

    makeIdUnique(svg, linearGradients, 0, 'fill');
    makeIdUnique(svg, linearGradients, 1, 'stroke');
  },
  methods: {
    flip() {
      if (this.isMobile) {
        this.$emit('flip');
      } else {
        this.$refs.overlay.classList.toggle('country-card__back--visible');
        this.$refs.button.classList.toggle('country-card__button--gradient');
      }
    },
  },
};
